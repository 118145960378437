@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://use.typekit.net/lnt4noa.css");

body{
  font-family: aller,sans-serif;
  font-style: normal;
}

.no-style{
  border:none;
  background-color: transparent;
  padding:0;
}

.close span{
  font-size:1.5rem;
}

input.team-name{
  font-size:2.25rem;
  font-weight:700;
  width:auto;
  border:none;
  padding:0;
}

.btn{
  white-space: nowrap;
}

.tooltip-show{
  opacity: 0.9;
  display: flex;
  justify-content: center;
  bottom: -170%;
}

.are-you-sure-popup{
  display: block;
}

.primary{
  color:#3c5876 !important;
}

.bg-primary{
  background: #3c5876 !important;
}

.valid-until-header{
  padding-right:45px;
}

.button-container{
  width:100%;
  position: relative;
    button{
    flex-grow:1;
      @media(max-width:767px){
        width:100%;
      }
    }

    button.no-style, a{
      @media(max-width:767px){
        width:auto;
      } 
    }
}

table{
  width:100%;
  max-width: 100%;

thead{
  background-color: #eef2f7;
}

  th{
    padding:0 15px;
  }

  td{
    padding:5px 15px;
  }

  @media(max-width:767px){
    th, td, td a{
      font-size:14px !important;
    }
    td{
      padding:5px;
    }
    th{
      padding:0 5px;
    }
  }

  @media(max-width:430px){
    th, td, td a, td .text, .no-certificate{
      font-size:12px !important;
    }
    .progress-tag{
      width: 78px !important;
    }
  }
}

.rounded-circle{
  text-transform: uppercase;
}

.filters-container{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media(max-width:767px){
    justify-content: flex-start;
    div, button, a{
      width:100%
    }
  }
}

.terms-modal{
  border:1px solid #dee2e6;
  border-radius: 5px;
}

.spinner-wrapper{
  width:100%;
  height:80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-wrapper .spinner-border{
    height:8rem;
    width: 8rem;
    border-width: 0.3rem;
}

.wrapper{
  min-height: 100vh;
}

.footer-nav{
  padding:0;
  margin:0;
  list-style: none;
  display: flex;
  flex-direction: row;

  @media(max-width:430px){
    flex-direction: column;
  }
}

.footer-nav a:hover,
  .footer-nav a{
  font-size:14px;
  color:rgb(108, 117, 125);
}

.navbar-nav .dropdown-menu{
  position: absolute;
  left:unset;
  right:-5px;
}

.copyright-text{
  padding: 0.5rem 1rem;
}

.refreshing svg{
  animation: 0.75s linear infinite spinner-border;
}

.popover-body{
  width: 250px;
  color:#3c5876 !important;
}
.popover-header{
  background-color: #3c5876 !important;
  color:white !important;
}
.domain-popover{
   .popover-body{
      width:500px;
      background: white;
      border:1px solid #3c5876;
      border-radius:0 0 5px 5px;
   } 
   .popover-header{
      width:500px;
      border:1px solid #3c5876;
    }
}
@media(max-width:430px){
.popover-link{
display: none;
}
}

.btn-primary{
  background-color: #3c5876 !important;
  border-color: #3c5876 !important;
  box-shadow: 0px 2px 6px 0px #3c5876;
}

.delete{
  flex-grow: unset !important;
}

hr{
  background-color: #3c5876 !important;
}

.nav-link,
.domain-link{
  font-size: 18px;
}
.domain-link:hover{
  text-decoration: underline;
}
.topnav{
  background-color: #f4f6fb !important;
}
.dropdown-menu{
  background-color:white !important;
}
.nav-link:hover{
  text-decoration: underline;
  background-color: #f4f6fb;
}

.progress-tag{
  height:20px;
  border-radius:8px;
  overflow: hidden;
  width:100px;
  position: relative;
}

.progress-tag .text{
  z-index: 6;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height:100%;
  font-size:14px;
  background-color: transparent;
}

.account-user-avatar div{
  background-color: #eef2f7;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 50px;

  @media(max-width:767px){
  line-height: 30px;
  width: 30px;
    height: 30px;
  }
}

.status-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

@for $i from 0 through 100 {
  .pro-green-#{$i}{
    &.progress-tag {
      background-color: mix(#ADC90E, #FFED00, $i);
    }

   ~ svg{
      fill:mix(#ADC90E, #FFED00, $i);
    }
  }
}

@for $i from 0 through 100 {
  .pro-yellow-#{$i}{
    &.progress-tag {
      background-color: mix(#FFED00, #FBBC44, $i);
    }

   ~ svg{
      fill:mix(#FFED00, #FBBC44, $i);
    }
  }
}

@for $i from 0 through 100 {
  .pro-orange-#{$i}{
    &.progress-tag {
      background-color: mix(#FBBC44, #EE763C, $i);
    }

   ~ svg{
      fill:mix(#FBBC44, #EE763C, $i);
    }
  }
}

@for $i from 0 through 100 {
  .pro-red-#{$i}{
    &.progress-tag {
      background-color: mix(#EE763C, #E4032E, $i);
    }

   ~ svg{
      fill:mix(#EE763C, #E4032E, $i);
    }
  }
}

.progress-disc-container{
  display: block;
  position: relative;
  height:35px;
  width: 35px;
  transform: rotate(40deg);

  @media(max-width:400px){
    height:25px;
    width:25px;
  }
}

.progress-disc-container img,
  .progress-disc-container svg{
  height:100%;
  width:100%;
  position: absolute;
  z-index: 4;
  transform: rotate(-40deg);
}

.progress-disc-container .disc{
  width: 97%;
  height: 97%;
  margin:1px 0 1px 0;
  border-radius: 50%;
  background-image: conic-gradient(#ADC90E, #FFED00, #FBBC44, #EE763C, #E4032E);
  position: absolute;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.progress-disc-container .disc:nth-child(1)::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height:80%;
  background: white;
  border-radius: inherit;
}

.progress-disc-container .progress-disc{
  width: 105%;
  height: 105%;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  top:-1px;
  left:-1px;
}

@for $i from 0 through 100 {
  .progress-disc-container .progress-disc.pro-#{$i} {
    background-image: conic-gradient(transparent #{percentage(($i)/100)}, white #{percentage(($i)/100)});
  }
}